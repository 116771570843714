.iphone-x {
  position: relative;

  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
}

.iphone-x:before,
.iphone-x:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}

.iphone-x .iphone-camera,
.iphone-x .iphone-speaker {
  position: absolute;
  display: block;
  color: transparent;
}

.iphone-x .iphone-camera {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}

.iphone-x .iphone-speaker {
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
}

.iphone-x .iphone-camera:after {
  content: "";
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}

.text-height {
  transform: scale(1, 1.5);
}

@media screen and (max-width: 1118px) {
  .text-height {
    transform: scaleX(0.6);
  }
}
